import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/theme/layout'
import SEO from '../components/seo'
import styles from '../scss/puppies-for-sale.scss'
import { handleInnerNavigation, attachSwipeListener, dettachSwipeListener, buildIntersectionObserver,
  getHeaderSize, getElementSizeBySelector } from '../helpers'
import { findIndex } from 'lodash'
import AddThis  from '../components/add-this/add-this'
import { WIDTH_BREAKPOINTS } from '../constants'

class ThePuppyScam extends React.Component {
  constructor(props) {
    super(props);
    const title = 'Puppy Scams in the U.S. | Honest Paws®'
    this.seo = {
      title: title,
      meta: {
        title: 'Puppy Scams in the U.S. Have Increased by Over 280% During COVID-19',
        description: 'Looking for puppies for sale? Here are 15 of the worst states to buy a puppy online due to scams and fraudulent ads.',
        image: this.props.data.metaImage.childImageSharp.fluid
      },
      children: [
        <link rel="preload" href="/img/puppy-scams/desktop/intro/D-Main-Image-1.svg" as="image" key="image-preload-1" />,
        <link rel="preload" href="/img/puppy-scams/responsive/intro/R-Main-Image-1.svg" as="image" key="image-preload-2" />,
        <link rel="preload" href="/img/puppy-scams/desktop/intro/D-Fraudulent-Skull.svg" as="image" key="image-preload-3" />,
        <link rel="preload" href="/img/puppy-scams/desktop/intro/D-Covid-Puppy-Scams.svg" as="image" key="image-preload-4" />,
        <link rel="preload" href="/img/puppy-scams/responsive/intro/R-Covid-Puppy-Scams.svg" as="image" key="image-preload-5" />,
        <link rel="preload" href="/img/puppy-scams/desktop/intro/D-2400-Complaints.svg" as="image" key="image-preload-6" />,
        <link rel="preload" href="/img/puppy-scams/desktop/intro/D-Empty-Box.svg" as="image" key="image-preload-7" />,
        <link rel="preload" href="/img/puppy-scams/desktop/intro/D-How-They-Get-You.svg" as="image" key="image-preload-8" />,
        <link rel="preload" href="/img/puppy-scams/desktop/intro/D-States-Chart-1.svg" as="image" key="image-preload-9" />,
        <link rel="preload" href="/img/puppy-scams/responsive/intro/R-States-Chart-1.svg" as="image" key="image-preload-10" />,
        <link rel="preload" href="/img/puppy-scams/desktop/intro/D-States-Chart-2.svg" as="image" key="image-preload-11" />,
        <link rel="preload" href="/img/puppy-scams/responsive/intro/R-States-Chart-2.svg" as="image" key="image-preload-12" />,
        <link rel="preload" href="/img/puppy-scams/desktop/adoption/BG-2.svg" as="image" key="image-preload-13" />,
        <link rel="preload" href="/img/puppy-scams/responsive/adoption/R-BG-2.svg" as="image" key="image-preload-14" />,
        <link rel="preload" href="/img/puppy-scams/desktop/adoption/D-Main-Image-2.svg" as="image" key="image-preload-15" />,
        <link rel="preload" href="/img/puppy-scams/responsive/adoption/R-Main-Image-2.svg" as="image" key="image-preload-16" />,
        <link rel="preload" href="/img/puppy-scams/desktop/adoption/D-Dog-Cat-Kennel-v2.svg" as="image" key="image-preload-17" />,
        <link rel="preload" href="/img/puppy-scams/desktop/adoption/D-Shelters-Weekly-Monthly-Yearly.svg" as="image" key="image-preload-18" />,
        <link rel="preload" href="/img/puppy-scams/desktop/adoption/D-53-Percent-v2.svg" as="image" key="image-preload-19" />,
        <link rel="preload" href="/img/puppy-scams/desktop/adoption/D-Sad-Couch.svg" as="image" key="image-preload-20" />,
        <link rel="preload" href="/img/puppy-scams/desktop/adoption/D-Symptoms.svg" as="image" key="image-preload-21" />,
        <link rel="preload" href="/img/puppy-scams/desktop/adoption/D-Heart.svg" as="image" key="image-preload-22" />,
        <link rel="preload" href="/img/puppy-scams/desktop/adoption/D-Avatar-Vanessa.svg" as="image" key="image-preload-23" />,
        <link rel="preload" href="/img/puppy-scams/desktop/adoption/D-Zelle-Warning.svg" as="image" key="image-preload-24" />,
        <link rel="preload" href="/img/puppy-scams/desktop/adoption/D-Arm-Money.svg" as="image" key="image-preload-25" />,
        <link rel="preload" href="/img/puppy-scams/desktop/adoption/D-3-Warnings.svg" as="image" key="image-preload-26" />,
        <link rel="preload" href="/img/puppy-scams/desktop/adoption/D-Avatar-Stacy.svg" as="image" key="image-preload-27" />,
        <link rel="preload" href="/img/puppy-scams/desktop/adoption/D-Bulldog.svg" as="image" key="image-preload-28" />,
        <link rel="preload" href="/img/puppy-scams/desktop/adoption/D-Girl-Wallet.svg" as="image" key="image-preload-29" />,
        <link rel="preload" href="/img/puppy-scams/desktop/adoption/D-Avatar-Lindsey.svg" as="image" key="image-preload-30" />,
        <link rel="preload" href="/img/puppy-scams/desktop/adoption/D-Woman-Confused.svg" as="image" key="image-preload-31" />,
        <link rel="preload" href="/img/puppy-scams/desktop/warning-signs/BG-3.svg" as="image" key="image-preload-32" />,
        <link rel="preload" href="/img/puppy-scams/responsive/warning-signs/R-BG-3.svg" as="image" key="image-preload-33" />,
        <link rel="preload" href="/img/puppy-scams/desktop/warning-signs/D-Warning-Signs-People.svg" as="image" key="image-preload-34" />,
        <link rel="preload" href="/img/puppy-scams/desktop/warning-signs/D-Google-Trends-Chart.svg" as="image" key="image-preload-35" />,
        <link rel="preload" href="/img/puppy-scams/desktop/warning-signs/D-Attention-Stop.svg" as="image" key="image-preload-36" />,
        <link rel="preload" href="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-1.svg" as="image" key="image-preload-37" />,
        <link rel="preload" href="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-1-image.svg" as="image" key="image-preload-38" />,
        <link rel="preload" href="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-2.svg" as="image" key="image-preload-39" />,
        <link rel="preload" href="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-2-Image.svg" as="image" key="image-preload-40" />,
        <link rel="preload" href="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-3.svg" as="image" key="image-preload-41" />,
        <link rel="preload" href="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-3-Image.svg" as="image" key="image-preload-42" />,
        <link rel="preload" href="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-4.svg" as="image" key="image-preload-43" />,
        <link rel="preload" href="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-4-Image.svg" as="image" key="image-preload-44" />,
        <link rel="preload" href="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-5.svg" as="image" key="image-preload-45" />,
        <link rel="preload" href="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-5-Image.svg" as="image" key="image-preload-46" />,
        <link rel="preload" href="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-6.svg" as="image" key="image-preload-47" />,
        <link rel="preload" href="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-6-Image.svg" as="image" key="image-preload-48" />,
        <link rel="preload" href="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-7.svg" as="image" key="image-preload-49" />,
        <link rel="preload" href="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-7-Image.svg" as="image" key="image-preload-50" />,
        <link rel="preload" href="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-8.svg" as="image" key="image-preload-51" />,
        <link rel="preload" href="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-8-Image.svg" as="image" key="image-preload-52" />,
        <link rel="preload" href="/img/puppy-scams/desktop/warning-signs/D-Petting-Dog.svg" as="image" key="image-preload-53" />
      ]
    }


    this.sections = [
      {
        label: 'Intro',
        section: 'intro',
        icon: 'icon-info'
      },
      {
        label: 'Adoption',
        section: 'adoption',
        icon: 'icon-adoption'
      },
      {
        label: 'Warning Signs',
        section: 'warning-signs',
        icon: 'icon-warning'
      }
    ]

    this.state = {
      active_section: 'intro',
      is_active_section: true,
      addThisEnabled: false
    }

    this.anchorsOffset  = 120

    this.updateSection = this.updateSection.bind(this)
    this.nextSection = this.nextSection.bind(this)
    this.prevSection = this.prevSection.bind(this)
    this.showShareDialog = this.showShareDialog.bind(this)
    this.handleSectionsNavSelection = this.handleSectionsNavSelection.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.handleScrollTO = this.handleScrollTO.bind(this)
    this.calculateAnchorOffset = this.calculateAnchorOffset.bind(this)
    this.enableAddThis = this.enableAddThis.bind(this)
  }

  componentDidMount() {
    attachSwipeListener(this.sectionsRef, {
      left: this.nextSection,
      right: this.prevSection
    }, 150)

    setTimeout(this.enableAddThis, 0)

    if ('IntersectionObserver' in window) {
      // Lazy load divs containing images as background
      this.observer = buildIntersectionObserver(document.querySelectorAll('[data-lazy-class]'), (target, element) => {
        const className = target.getAttribute('data-lazy-class')
        if (!element || element.isIntersecting && target.className.indexOf(className) < 0) {
          target.className = `${target.className} ${className}`
        }
      }, {
        rootMargin: "0px 0px 200px 0px"
      })
    }

    /** Settings below will be used by the "share" bar **/
    this.previousScrollYOffset = window.pageYOffset
    this.sharingOptionsHidden = true
    window.addEventListener('scroll', this.handleScroll)
    this.updateSectionPositions()
    this.calculateAnchorOffset()
  }

  componentDidUpdate() {
    this.handleSectionsNavSelection()
    this.updateSectionPositions()
  }

  componentWillUnmount() {
    if (IntersectionObserver in window) {
      this.observer.disconnect();
    }
    window.removeEventListener('scroll', this.handleScroll)
  }

  calculateAnchorOffset() {
    const headerSize = getHeaderSize()
    const sectionsMenuSize = getElementSizeBySelector(this.sectionsListContainerRef)
    this.anchorsOffset = headerSize.height + sectionsMenuSize.height
  }

  /**
   * Update anchors positions (because due to lazyload it fails sometimes)
   * @return {void}
   */
  updateSectionPositions() {
    this.sectionsPositions = Array.from(document.querySelectorAll('section[id^="section-"]')).map((element) => {
      const style = window.getComputedStyle(element);
      return element.getBoundingClientRect().top + window.scrollY - (parseInt(style.paddingTop) || 0) + (parseInt(style.marginTop) || 0)
    })
  }

  /**
   * Enables AddThis in case it's desktop
   * @return {void}
   */
  enableAddThis() {
    if (window.outerWidth >= WIDTH_BREAKPOINTS.lg) {
      this.setState({
        addThisEnabled: true
      })
    }
  }

  /**
   * Handles scroll (shows/hide sharing options)
   * @param  {Event} e
   * @return {void}
   */
  handleScroll(e) {
    const currentPageYOffset = window.pageYOffset

    if (currentPageYOffset < this.previousScrollYOffset && !this.sharingOptionsHidden) {
      this.sharingOptionsRef.style.display = 'block'
      this.sharingOptionsHidden = true
    } else if (currentPageYOffset >= this.previousScrollYOffset && this.sharingOptionsHidden) {
      this.sharingOptionsRef.style.display = 'none'
      this.sharingOptionsHidden = false
    }

    this.previousScrollYOffset = currentPageYOffset;

    if (window.outerWidth >= WIDTH_BREAKPOINTS.lg) {
      /** Handles selected section display - only on desktop **/
      if (this.scrollTO) {
        clearTimeout(this.scrollTO)
      }
      this.scrollTO = setTimeout(() => {this.handleScrollTO(e)}, 200)
    }
  }

  /**
   * Handles scroll navigation (TO in name refers to timeOut because it's called by a timeout function)
   * @param  {Event} e
   * @return {void}
   */
  handleScrollTO(e) {
    let found = false
    let x = 0
    const length = this.sectionsPositions.length
    const pos = window.scrollY + this.anchorsOffset
    this.updateSectionPositions()
    while (!found && x < length) {
      if (x < length - 1) {
        found = pos <= this.sectionsPositions[0] || (pos >= this.sectionsPositions[x] && pos < this.sectionsPositions[x + 1] )
      } else {
        found = true
      }
      if (!found) {
        x++
      }
    }

    if (this.state.active_section !== x && x < this.sections.length) {
      this.updateSection(null, this.sections[x].section, true)
    }
  }

  /**
   * Handles Nav Menu (Sections) navigation
   * @return {void}
   */
  handleSectionsNavSelection() {
    const activeItem = this.sectionsListContainerRef.getElementsByClassName('active')[0]
    const itemsContainer = activeItem.parentElement
    if ((activeItem.offsetLeft + activeItem.offsetWidth) > itemsContainer.offsetWidth) {
      this.sectionsListContainerRef.scrollLeft = activeItem.offsetLeft
    } else if (activeItem.offsetLeft < this.sectionsListContainerRef.scrollLeft) {
      this.sectionsListContainerRef.scrollLeft = activeItem.offsetLeft - activeItem.offsetWidth
    }
  }

  /**
   * Updates Visible Section
   * @param  {Event} e
   * @param  {string} section
   * @param  {boolean} avoidNavigation
   * @return {void}
   */
  updateSection(e, section, avoidNavigation = false) {
    let moveToTop = true

    if (window.outerWidth >= WIDTH_BREAKPOINTS.lg) {
      if (!avoidNavigation) {
        handleInnerNavigation(`section-${section}`, -this.anchorsOffset)
      }
      moveToTop = false
    }
    this.setState({
      active_section: section,
      is_active_section: false
    }, () => {
      setTimeout(() => {
        this.setState({
          is_active_section: true
        })
        if (moveToTop) {
          window.scrollTo(0, 0)
        }
      }, 0)
    })
  }

  /**
   * Moves to next section
   * @return {void}
   */
  nextSection() {
    const index = findIndex(this.sections, {section: this.state.active_section})
    if (index < this.sections.length - 1) {
      this.updateSection(null, this.sections[index + 1].section)
    }
  }

  /**
   * Moves to prev section
   * @return {void}
   */
  prevSection() {
    const index = findIndex(this.sections, {section: this.state.active_section})
    if (index > 0) {
      this.updateSection(null, this.sections[index - 1].section)
    }
  }

  showShareDialog(e) {
    e && e.preventDefault();
    navigator && navigator.share && navigator.share({
      title: 'Puppy Scams in the U.S. Have Increased by Over 280% During COVID-19',
      text: 'Looking for puppies for sale? Here are 15 of the worst states to buy a puppy online due to scams and fraudulent ads.',
      url: location.href,
    });
  }

  render() {
    return (
      <Layout containerClassName={`page page-the-puppy-scams`} bodyClassName="chat-hidden" location={this.props.location} hideFooter={true} hideHeader={true}>
        <SEO { ...this.seo } />
        <nav className="" id="sections-nav" ref={ (ref) => this.sectionsListContainerRef = ref }>
          <ul className="p-0">
            { this.sections.map((section, i) => {
              return (
                <li key={`section-${i}`} className={`${this.state.active_section === section.section ? 'active': ''}`}>
                  <a href={`#${section.section}`} onClick={(e) => { e.preventDefault(); this.updateSection(e, section.section) }}>
                    <img src={`/img/puppy-scams/nav-icons/${section.icon}.svg`} alt={section.label} />
                    <img src={`/img/puppy-scams/nav-icons/${section.icon}-selected.svg`} alt={section.label} className="selected"/>
                    <span>{section.label}</span>
                  </a>
                </li>
              )
            }) }
          </ul>
        </nav>
        <div className="sharing-options" ref={(ref) => this.sharingOptionsRef = ref}>
          <div className="sharing-options-inner">
            <span className="d-lg-none">1.7k Shares</span>
            { !this.state.addThisEnabled ? (
              <div className="sharing-options-networks">
                <a href="#" onClick={(e) => this.showShareDialog(e, 'facebook')} title="Share on Facebook">
                  <img src="/img/the-great-hemp-scam/blog-main/social-facebook.svg" className="sharing-option" alt="facebook" />
                </a>
                <a href="#" onClick={(e) => this.showShareDialog(e, 'twitter')} title="Share on Twitter">
                  <img src="/img/the-great-hemp-scam/blog-main/social-twitter.svg" className="sharing-option" alt="twitter" />
                </a>
                <a href="#" onClick={(e) => this.showShareDialog(e, 'linkedin')} title="Share on Linkedin">
                  <img src="/img/the-great-hemp-scam/blog-main/social-linkedin.svg" className="sharing-option" alt="linkedin" />
                </a>
                <a href="#" onClick={(e) => this.showShareDialog(e, 'pinterest')} title="Share on Pinterest">
                  <img src="/img/the-great-hemp-scam/blog-main/social-pinterest.svg" className="sharing-option" alt="pinterest" />
                </a>
                <a href="#" onClick={(e) => this.showShareDialog(e, 'blogger')} title="Share on Blogger">
                  <img src="/img/the-great-hemp-scam/blog-main/social-blogger.svg" className="sharing-option" alt="blogger" />
                </a>
              </div>
            ) : (
              <AddThis>
                <div className="addthis_toolbox addthis_default_style sharing-options-networks">
                  <a className="addthis_button_facebook" title="Share on Facebook"><img src="/img/the-great-hemp-scam/blog-main/social-facebook.svg" className="sharing-option" alt="facebook" /></a>
                  <a className="addthis_button_twitter" title="Share on Twitter"><img src="/img/the-great-hemp-scam/blog-main/social-twitter.svg" className="sharing-option" alt="twitter" /></a>
                  <a className="addthis_button_pinterest" title="Share on Pinterest"><img src="/img/the-great-hemp-scam/blog-main/social-pinterest.svg" className="sharing-option" alt="pinterest" /></a>
                  <a className="addthis_button_linkedin" title="Share on Linkedin"><img src="/img/the-great-hemp-scam/blog-main/social-linkedin.svg" className="sharing-option" alt="linkedin" /></a>
                  <a className="addthis_button_blogger" title="Share on Blogger"><img src="/img/the-great-hemp-scam/blog-main/social-blogger.svg" className="sharing-option" alt="blogger" /></a>
                </div>
              </AddThis>
            ) }
            <span className="d-none d-lg-block">1.7k Shares</span>
          </div>
        </div>
        <div className="sections-container" ref={ (ref) => this.sectionsRef = ref }>
          {/* Section Intro */}
          <section className={`${this.state.is_active_section && 'active'} ${this.state.active_section === 'intro' && 'd-block'}`} id="section-intro">
            <div className="intro row">
              <h1 className="col-12 col-md-8 mx-auto mb-md-3 p-0 text-left">Puppy Scams in the U.S. Have Increased by Over 280% During COVID-19:</h1>
              <div className="col-7 col-md-5 offset-md-2 p-0 text-left subtitle">Here are the Top 15 Worst <br className="d-none d-lg-block"/> States to Buy a Puppy Online</div>
              <div className="d-flex col-5 col-md-4 ml-md-2 p-0 align-items-center justify-content-end justify-content-md-start">
                <a href="#intro-first-content" className="btn btn-success" onClick={(e) => handleInnerNavigation(e, -this.anchorsOffset) }>Let's Dive In</a>
              </div>
              <img src="/img/puppy-scams/desktop/intro/D-Main-Image-1.svg" alt="Puppy scams" data-lazy-class="img-solid" loading="lazy" className="d-none d-lg-block mt-5 mx-auto d-main-img" />
              <img src="/img/puppy-scams/responsive/intro/R-Main-Image-1.svg" alt="Puppy scams" data-lazy-class="img-solid" loading="lazy" className="r-img d-lg-none"/>
            </div>
            <img src="/img/puppy-scams/desktop/intro/BG-1.svg" alt="" data-lazy-class="img-solid" loading="lazy" className="d-none d-lg-block w-100 bg-img" />

            <div className="content" id="intro-content">
              <p id="intro-first-content">
                The new face of crime in the age of Corona: how scammers are taking advantage of social distancing and
                creating a booming business out of fraudulent puppy ads.
              </p>
              <img src="/img/puppy-scams/desktop/intro/D-Fraudulent-Skull.svg" alt="Fraudulent puppy ads" data-lazy-class="img-solid" loading="lazy" />
              <p>
                Puppy scams are nothing new in the world of internet fraudulence. The Better Business Bureau (BBB) has
                been tracking puppy scams for years, providing publicly accessible information on scams from as far back as 2015.
              </p>
              <img src="/img/puppy-scams/desktop/intro/D-Covid-Puppy-Scams.svg" alt="Covid cases and puppy scams" className="d-none d-lg-block" data-lazy-class="img-solid" loading="lazy" />
              <img src="/img/puppy-scams/responsive/intro/R-Covid-Puppy-Scams.svg" alt="Covid cases and puppy scams" className="d-lg-none r-covid-puppy-scams" data-lazy-class="img-solid" loading="lazy" />
              <p>
                However, in the age of COVID-19, puppy scams have become a booming business in the United States. From
                April 2020 (shortly after many states instituted curfews and lockdowns) to September 2020, over
              </p>
              <p className="highlight">
                2,400 complaints were filed with the BBB in relation to a puppy scam. That’s an increase of over 280%
                from the same time period in 2019, when only 627 complaints were filed.
              </p>
              <img src="/img/puppy-scams/desktop/intro/D-2400-Complaints.svg" className="d-2400-complaints" alt="Puppy scam complaints" data-lazy-class="img-solid" loading="lazy" />
              <p>
                What has led to this unprecedented increase in criminal activity with something as innocuous as
                puppies at its core?
              </p>
              <p>
                The new normal Americans are living in the wake of COVID-19 has unfortunately created a prime breeding
                ground for internet scams. Nationwide commitments to social distancing have allowed scammers the
                ability to deny interested parties the opportunity to meet their new puppy in person before handing
                over an adoption fee. This has led to thousands of Americans paying to adopt a puppy sight unseen—often
                to the tune of thousands of dollars.
              </p>
              <img src="/img/puppy-scams/desktop/intro/D-Empty-Box.svg" alt="Puppy box" data-lazy-class="img-solid" loading="lazy" />
              <p>
                Scammers have additionally conned their victims by demanding payment for climate-controlled crates for
                puppies needing to be shipped to their new home, fraudulent transportation services, and non-existent,
                corona-related vaccinations.
              </p>
              <h3 className="text-center mb-4">How They Get You</h3>
              <img src="/img/puppy-scams/desktop/intro/D-How-They-Get-You.svg" alt="How they scam you" data-lazy-class="img-solid" loading="lazy" />
              <p>
                When it comes to the worst states to buy puppies online, here are the top 15 offenders based on the
                number of complaints filed with the BBB between April and September 2020:
              </p>
              <p className="highlight text-left">
                Number of Complaints filed with the Better Business Bureau Between April and September 2020
              </p>
              <img src="/img/puppy-scams/desktop/intro/D-States-Chart-1.svg" className="d-none d-lg-block" alt="Complaints filed with the better business bureau" data-lazy-class="img-solid" loading="lazy" />
              <img src="/img/puppy-scams/responsive/intro/R-States-Chart-1.svg" className="d-lg-none w-100" alt="Complaints filed with the better business bureau" data-lazy-class="img-solid" loading="lazy" />
              <p>
                **(Infographic use is welcomed with proper citation and link.)
              </p>
              <p>
                And here is a breakdown of the average amount of money puppy scam victims have lost in each of the
                top 15 states, as well as the digital money app most commonly used in each state:
              </p>
              <p className="highlight text-left d-lg-none">
                The Top 15 Worst States to Buy a Puppy Online
              </p>
              <img src="/img/puppy-scams/desktop/intro/D-States-Chart-2.svg" className="d-none d-lg-block" alt="Worst states to buy a puppy online" data-lazy-class="img-solid" loading="lazy" />
              <img src="/img/puppy-scams/responsive/intro/R-States-Chart-2.svg" className="d-lg-none w-100" alt="Worst states to buy a puppy online" data-lazy-class="img-solid" loading="lazy" />
            </div>
          </section>
          {/* End Section Intro */}

          {/* Section Adoption */}
          <section className={`${this.state.is_active_section && 'active'} ${this.state.active_section === 'adoption' && 'd-block'}`} id="section-adoption">
            <div className="intro">
              <img src="/img/puppy-scams/desktop/adoption/BG-2.svg" alt="" data-lazy-class="img-solid" loading="lazy" className="d-none d-lg-block w-100 bg-img" />
              <img src="/img/puppy-scams/responsive/adoption/R-BG-2.svg" alt="" data-lazy-class="img-solid" loading="lazy" className="d-lg-none w-100 bg-img" />
            </div>

            <div className="content" id="adoption-content">
              <h2 className="col-lg-6 mx-auto p-0 d-none d-lg-block">Why Pet Adoptions Have Skyrocketed During COVID-19 </h2>
              <img src="/img/puppy-scams/desktop/adoption/D-Main-Image-2.svg" alt="Pet adoptions" data-lazy-class="img-solid" loading="lazy" className="d-none d-lg-block mt-5 mx-auto" />
              <img src="/img/puppy-scams/responsive/adoption/R-Main-Image-2.svg" alt="Pet adoptions" data-lazy-class="img-solid" loading="lazy" className="d-lg-none"/>
              <h2 className="col-12 p-0 d-lg-none">Why Pet Adoptions Have Skyrocketed During COVID-19 </h2>

              <p>
                According to the American Society for the Prevention of Cruelty to Animals (ASPCA)
              </p>
              <a target="_blank" href="https://www.aspca.org/animal-homelessness/shelter-intake-and-surrender"><img src="/img/puppy-scams/desktop/adoption/D-Dog-Cat-Kennel-v2.svg" alt="Animal shelter" data-lazy-class="img-solid" loading="lazy" /></a>
              <p>
                However, the onset of a nationwide pandemic at the beginning of 2020 saw animal adoption rates soar
                higher than ever, with many rescues becoming overwhelmed with adoption applications and with breeders
                now maintaining waiting lists that extend well into 2021.
              </p>
              <p>
                At the <a target="_blank" href="https://jerseyshoreanimalcenter.org/">Jersey Shore Animal Shelter</a>, adoptions have been up by 15%. <a href="http://www.motleyzoo.org/" target="_blank">The Motley Zoo Animal Rescue based</a> in
                Redmond, Washington has also seen record numbers. Their monthly adoption rate has increased by 80%
                compared to what it was in 2019.
              </p>
              <img src="/img/puppy-scams/desktop/adoption/D-Shelters-Weekly-Monthly-Yearly.svg" alt="Shelter adoptions" data-lazy-class="img-solid" loading="lazy" />
              <p>
                Breeders across the nation have also seen unprecedented demand for puppies, with numerous derivatives
                of the poodle breed being among the most popularly sought out pets this year. <a href="https://silvertondoodles.com/" target="_blank">Silverton Doodles</a>, based
                out of Silverton, Oregon, has seen weekly inquiries about available puppies increase by 200%. At the
                beginning of the pandemic, they adopted out an entire litter of puppies within 24 hours. In addition
                to this, they regularly receive referrals from other breeders who are unable to meet the growing
                demand for puppies.
              </p>
              <p>
                For many of these aspiring pet owners, it’s simply companionship that they’re seeking.
              </p>
              <p>
                Social distancing and quarantine this year have taken a toll on the mental health of Americans,
              </p>
              <a href="https://www.kff.org/coronavirus-covid-19/report/kff-health-tracking-poll-july-2020/" target="_blank">
                <img src="/img/puppy-scams/desktop/adoption/D-53-Percent-v2.svg" alt="Mental health impacts" data-lazy-class="img-solid" loading="lazy" />
              </a>
              <p className="highlight">
                Reporting that stress and worry over the COVID-19 pandemic has had a negative impact on their mental and emotional wellbeing.
              </p>
              <img src="/img/puppy-scams/desktop/adoption/D-Sad-Couch.svg" alt="Emotional wellbeing" data-lazy-class="img-solid" loading="lazy" />
              <p>
                <a href="https://www.cdc.gov/mmwr/volumes/69/wr/mm6932a1.htm?s_cid=mm6932a1_w" target="_blank">Researchers</a> from
                the Centers for Disease Control & Prevention (CDC) found that reports of anxiety,
                depression, and suicidal ideation were three to four times higher following the corona outbreak when
                compared with 2019. Over 13% of adults also reported starting or increasing substance abuse.
              </p>
              <img src="/img/puppy-scams/desktop/adoption/D-Symptoms.svg" alt="Centers for Disease Control research" data-lazy-class="img-solid" loading="lazy" />
              <p>
                It’s no secret that the unconditional love a dog provides has a positive impact on a person’s health.
                Mounting scientific evidence shows the almost magical effect dogs have on us, from decreasing the
                risk of <a href="https://www.health.harvard.edu/staying-healthy/having-a-dog-can-help-your-heart--literally" target="_blank">cardiovascular disease</a> and
                supporting healthy <a href="https://www.webmd.com/hypertension-high-blood-pressure/features/6-ways-pets-improve-your-health#1" target="_blank">blood pressure</a> to
                even lowering the risk of an early death by <a href="https://edition.cnn.com/2019/10/08/health/dogs-help-us-live-longer-wellness/index.html" target="_blank">24%</a>. In
                fact, a special <a href="https://www.health.harvard.edu/staying-healthy/get-healthy-get-a-dog" target="_blank">health report</a> issued
                by Harvard Medical School highlights how interactions with a dog are calming for people, leading to decreases in their levels of cortisol
                (a stress hormone) and increases in their levels of oxytocin (the 'bonding hormone' commonly released when a mother nurses her infant).
              </p>
              <img src="/img/puppy-scams/desktop/adoption/D-Heart.svg" alt="Lower early death" data-lazy-class="img-solid" loading="lazy" />
              <p>
                But perhaps the greatest gifts a dog offers is simply its companionship, loyalty, and love.
              </p>
              <img src="/img/puppy-scams/desktop/adoption/D-Avatar-Vanessa.svg" alt="Vanessa R, puppy scam victim" data-lazy-class="img-solid" loading="lazy" className="mb-1" />
              <p className="highlight text-center avatar-name">
                Vanessa R.
              </p>
              <p>
                It was this very companionship Vanessa R. from the San Francisco Bay Area sought to provide her elderly
                father when she began searching for a puppy back in October 2020. Her father, 72-years-old, had recently
                lost his Japanese Chin dog but was finally ready to open his heart to a new four-legged companion.
              </p>
              <p>
                Vanessa searched Japanese Chin rescues far and wide but was unable to find any available dogs.
                Eventually, she discovered the website Macus Chin, the maintainer of which promised Vanessa an
                11-week-old puppy for $800. For an additional $180, the puppy would be shipped right to her front door.
              </p>
              <img src="/img/puppy-scams/desktop/adoption/D-Zelle-Warning.svg" alt="Zelle" data-lazy-class="img-solid" loading="lazy" />
              <p>
                The breeder, however, didn’t accept credit card payments. Instead, the owner of Macus Chin instructed
                Vanessa to send payment through the popular money app Zelle. Although her bank account initially
                flagged the transaction as potentially fraudulent, Vanessa had already communicated with Macus Chin several
                times by that point and believed the breeder to be trustworthy.
              </p>
              <img src="/img/puppy-scams/desktop/adoption/D-Arm-Money.svg" alt="Fraudulent transactions" data-lazy-class="img-solid" loading="lazy" />
              <p>
                It was only when a follow-up email came soon afterward, requesting an additional $1,380 for shipping
                insurance, that Vanessa realized something was amiss. She contacted Macus Chin to express her confusion
                over this additional fee, which had never been brought up in their previous conversations, but the
                breeder stopped returning her emails. She promptly filed a police report as well as
                complaints with the Federal Trade Commission and BBB. Unfortunately, since the transaction was
                conducted via Zelle and because Vanessa had authorized the payment, there was no means by which
                she could get her money back.
              </p>
              <p>
                In retrospect, Vanessa admits that there were red flags along the way that she should’ve paid closer
                attention to. The breeder website, for instance, offered no information such as health records on the
                litter’s sire or dam. Nor were there multiple pictures available of the puppy she was interested in.
                In fact, she later found the picture of the exact puppy she’d wanted to adopt in a Facebook group
                dedicated to reporting puppy scams, where another woman had also been scammed by Macus Chin.
              </p>
              <img src="/img/puppy-scams/desktop/adoption/D-3-Warnings.svg" alt="Scam red flags" data-lazy-class="img-solid" loading="lazy" />
              <img src="/img/puppy-scams/desktop/adoption/D-Avatar-Stacy.svg" alt="Stacy G, puppy scam victim" data-lazy-class="img-solid" loading="lazy" className="mb-1" />
              <p className="highlight text-center avatar-name">
                Stacy G.
              </p>
              <p>
                Stacy G. from Santa Rosa, California knows the emotional impact that goes with trying to move on from
                animal loss with the addition of a new puppy. She had lost a French Bulldog named Delilah back in May 2020
                due to cancer. Delilah was a trained social therapy dog who visited convalescent hospitals with Stacy,
                an activity that made for a special bond between them and that also provided something special
                and comforting to others.
              </p>
              <p>
                Having no luck finding a French Bulldog through rescues and animal shelters, she turned to Craigslist,
                where she found an individual who wanted to rehome a French Bulldog. The adoption fee for the puppy
                was $400, but since the puppy was being shipped from New York (the original owner claimed to have
                needed to relocate last-minute to the east coast), Stacy would also need to pay $865 to a company
                called VIP Pet Relocators for a climate-controlled crate that would protect the puppy during shipping.
              </p>
              <img src="/img/puppy-scams/desktop/adoption/D-Bulldog.svg" alt="Pet adoption fees" data-lazy-class="img-solid" loading="lazy" />
              <p>
                Once she made the additional payment, Stacy looked forward to receiving her new French Bulldog puppy.
                However, a third email arrived in her inbox, informing her that her puppy had been shipped with
                another dog that had contracted Canine parvovirus and that she would need to pay $1,800 for her
                puppy to be quarantined and tested.
              </p>
              <p>
                It was at this point that Stacy suspected she had fallen victim to a scam. She had used CashApp to
                send payments for her puppy throughout the adoption process, but like many other digital money apps,
                because she had already authorized the payments, she was unable to recover her money. More importantly,
                she never received the puppy she had prepared her heart for, which she says was the most heartbreaking
                experience of the entire ordeal.
              </p>
              <img src="/img/puppy-scams/desktop/adoption/D-Girl-Wallet.svg" alt="Puppy scam payments" data-lazy-class="img-solid" loading="lazy" />
              <img src="/img/puppy-scams/desktop/adoption/D-Avatar-Lindsey.svg" alt="Lindsey B, puppy scam victim" data-lazy-class="img-solid" loading="lazy" className="mb-1" />
              <p className="highlight text-center avatar-name">
                Lindsey B.
              </p>
              <p>
                Lindsay B. from Sacramento, California is one of many puppy scam victims whose total money lost amounts in the thousands.
              </p>
              <p>
                With no children of her own, Lindsay has always enjoyed the companionship of dogs and sought to adopt
                a Goldendoodle to ease her loneliness during the pandemic. She found a breeder online who required
                that she fill out multiple questionnaires before approving her to adopt two puppies.
              </p>
              <p>
                Once approved and with a contract signed, the breeder walked her through sending payment ($2,600)
                through Zelle. Afterward, Lindsay received an email that provided her with tracking information on
                the shipping progress of her puppies as they made their way to California. For three days, the tracking
                website was updated with their progress, but then on the final day, the tracking updates
                suddenly stopped.
              </p>
              <p>
                Concerned about her puppies, Lindsay reached out to the breeder, who assured her he would check up on
                their delivery status. He never called back, however, and stopped answering Lindsay’s calls and emails.
              </p>
              <p>
                Lindsay contacted the local police, the FBI, and other authorities, but as with the majority of those
                in her position, little could be done to recover the money she’d lost.
              </p>
              <img src="/img/puppy-scams/desktop/adoption/D-Woman-Confused.svg" alt="Confusion" data-lazy-class="img-solid" loading="lazy" />

            </div>
          </section>
          {/* End Section Adoption */}

          {/* Section warning */}
          <section className={`${this.state.is_active_section && 'active'} ${this.state.active_section === 'warning-signs' && 'd-block'}`} id="section-warning-signs">
            <div className="intro">
              <img src="/img/puppy-scams/desktop/warning-signs/BG-3.svg" alt="" data-lazy-class="img-solid" loading="lazy" className="d-none d-lg-block w-100 bg-img" />
              <img src="/img/puppy-scams/responsive/warning-signs/R-BG-3.svg" alt="" data-lazy-class="img-solid" loading="lazy" className="d-lg-none w-100 bg-img" />
            </div>

            <div className="content" id="warning-signs-content">
              <img src="/img/puppy-scams/desktop/warning-signs/D-Warning-Signs-People.svg" alt="8 warning signs of a puppy scam" data-lazy-class="img-solid" loading="lazy" className="mt-5 mx-auto warning-first-img" />
              <h2 className="col-12 col-lg-6 mx-auto p-0">Before You Adopt: 8 Warning Signs of a Puppy Scam</h2>

              <p>
                With social distancing restrictions still in place for the foreseeable future, individuals and families
                will no doubt continue to seek out the loving companionship of a new pet to ease the loneliness of quarantine.
              </p>
              <p>
                Google Trends already showed a surge in searches for "puppies for sale" as Christmas 2020 approached,
                and this surge will only increase as people the world over continue to deal with pandemic-related limitations on socializing.
              </p>
              <img src="/img/puppy-scams/desktop/warning-signs/D-Google-Trends-Chart.svg" alt="Google trends report" data-lazy-class="img-solid" loading="lazy" />
              <p>
                To avoid falling victim to the rising wave of online puppy scams, however, here are 8 red flags
                individuals need to be aware of when browsing breeder websites and online ads for puppies:
              </p>
              <img src="/img/puppy-scams/desktop/warning-signs/D-Attention-Stop.svg" className="mb-6" alt="Attention sign" data-lazy-class="img-solid" loading="lazy" />
              <img src="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-1.svg" alt="One" data-lazy-class="img-solid" loading="lazy" />
              <p>
                The seller claims that due to social distancing regulations, you will not be able to see the animal in
                person before adoption and/or is unable to provide you with multiple pictures/videos of the puppy up for adoption.
              </p>
              <img src="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-1-image.svg" alt="Puppy photos and videos" data-lazy-class="img-solid" loading="lazy" className="r-img-w-100 mb-6" />
              <img src="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-2.svg" alt="Two" data-lazy-class="img-solid" loading="lazy" />
              <p>
                When performing an internet search of a picture of the puppy you’re considering, you notice the same
                picture appears on multiple websites or in Facebook groups. You can use Google’s helpful ‘reverse image’
                tool to find instances where the picture is being used elsewhere on the internet.
              </p>
              <img src="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-2-Image.svg" className="mb-6" alt="Social media accounts" data-lazy-class="img-solid" loading="lazy" />
              <img src="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-3.svg" alt="Three" data-lazy-class="img-solid" loading="lazy" />
              <p>
                When performing an internet search for the text from ads or testimonials on a breeder’s website or ad, you notice they’ve been copied from other websites.
              </p>
              <img src="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-3-Image.svg" className="mb-6" alt="Puppy reviews" data-lazy-class="img-solid" loading="lazy" />
              <img src="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-4.svg" alt="Four" data-lazy-class="img-solid" loading="lazy" />
              <p>
                The breeder’s website offers no information about the sire or dam of the litter and/or is unable to provide proof of health records or AKC Certification.
              </p>
              <img src="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-4-Image.svg" className="mb-6" alt="AKC certification" data-lazy-class="img-solid" loading="lazy" />
              <img src="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-5.svg" alt="Five" data-lazy-class="img-solid" loading="lazy" />
              <p>
                The seller asks for payment up front through Western Union, MoneyGram, a digital money app like Zelle or CashApp, or via a gift card.
              </p>
              <img src="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-5-Image.svg" className="mb-6" alt="Money apps" data-lazy-class="img-solid" loading="lazy" />
              <img src="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-6.svg" alt="Six" data-lazy-class="img-solid" loading="lazy" />
              <p>
                In the case of purebred breeds, the puppy in question is being offered at a significantly steep discount when compared with the average price for a puppy of its breed.
              </p>
              <img src="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-6-Image.svg" className="mb-6" alt="Adoption price comparison" data-lazy-class="img-solid" loading="lazy" />
              <img src="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-7.svg" alt="Seven" data-lazy-class="img-solid" loading="lazy" />
              <p>
                The seller or a third party asks for payment to cover additional items such as a climate-controlled
                crate for shipping, vaccinations, or transportation insurance/life insurance. In many cases, fraudulent
                emails will claim the shipping costs/crate rental feels will be refunded upon the puppy’s delivery. However, they never are.
              </p>
              <img src="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-7-Image.svg" className="mb-6 r-img-w-100" alt="Money exchanging hands" data-lazy-class="img-solid" loading="lazy" />
              <img src="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-8.svg" alt="Eight" data-lazy-class="img-solid" loading="lazy" />
              <p>
                The adoption contract contains multiple spelling or grammatical errors.
              </p>
              <img src="/img/puppy-scams/desktop/warning-signs/D-Warning-Number-8-Image.svg" alt="Contract" data-lazy-class="img-solid" loading="lazy" />
              <p>
                With puppy scams more rampant than ever, the best thing a person seeking a pet can do is turn to local
                animal shelters or rescue groups to find their new best friend. For those set on a purebred pet, it’s
                estimated that up to <a href="https://bestfriends.org/stories-blog-videos/latest-news/adopting-purebred-dog-shelter#:~:text=While%20breed%20rescue%20organizations%20are,the%20shelter%20system%20are%20purebreds" target="_blank">30%</a> of
                the animals in shelters or rescues are, in fact, purebred.
              </p>
              <p>
                Additionally, individuals can search for breeder associations or clubs (e.g. Golden Retriever Club of
                America, Poodle Club of America, etc.) to find reputable breeders affiliated with the American Kennel Club.
              </p>
              <img src="/img/puppy-scams/desktop/warning-signs/D-Petting-Dog.svg" alt="Puppy in a shelter" data-lazy-class="img-solid" loading="lazy" />
              <p>Written By: <a href="https://www.honestpaws.com/authors/lily-velez/">Lily Velez</a>.</p>
              <p>This article is brought to you by Honest Paws, the #1 Pet CBD brand and trusted source for the <a
                href="https://www.honestpaws.com/collections/cbd-oil-for-dogs/">best CBD oil for dogs</a>, <a href="https://www.honestpaws.com/products/cbd-oil-for-cats">best CBD oil for cats</a>,
                and <a href="https://www.honestpaws.com/collections/cbd-dog-treats/">best CBD treats for dogs</a>. With over 100,000 happy customers,
                there's a reason why Honest Paws is the #1 Pet CBD brand today!</p>

            </div>

          </section>
          {/* End Section warning */}

        </div>
      </Layout>
    )

  }


}

export default ThePuppyScam
export const ThePuppyScamQuery = graphql`
  query ThePuppyScamQuery {
    site {
      siteMetadata {
        title
        siteUrl
        siteUrlEs
      }
    }
    metaImage: file(relativePath: { eq: "puppy-scams/social-share-puppy-scams.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`